const NODE_ENV = process.env.REACT_APP_NODE_ENV ;
export const PUBLIC_RSA_KEY = process.env.REACT_APP_PUBLIC_RSA_KEY || 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDP/x/1sSj0DJNRBQfaOzKmhoCBIYDUvLqFqV9pzmOcAFHoPHdEI3iTLmrxMaSd54Kj2LgUuCIRxLdhALtP1JghNeDnbXZIk72Op/DDOdD470+vDWkQlLjbCb0iCSfGZ/zFRszERxvafoNwkjJVDMWuod013a2dkDp1Chc3HLrpdQIDAQAB';
export const is_encrypt = process.env.REACT_APP_IS_ENCRYPT || false;
console.log(is_encrypt);
let API_URL;
switch (NODE_ENV) {
    case 'production':
        API_URL = 'https://api.retailscanportal.com/';
        // console.log = () => {}
        break;
    case 'migration':
        API_URL = 'https://gprs-migration-api.geopits.com/';
        break;
    case 'local':
        API_URL = 'http://localhost:8000/';
        break;
    default:
        API_URL = 'https://gprs-api.geopits.com/';
        break;
}

export const AppConfig = {
    api_url: API_URL
};
